<script setup>
import { useAuthStore } from '@/stores';
import Nav from '@/components/Nav.vue';
import Alert from '@/components/Alert.vue';

const authStore = useAuthStore();
</script>

<template>
    <div class="app-container" :class="authStore.user && 'bg-light'">
        <Nav />
        <Alert />
        <div class="container pt-4 pb-4">
            <router-view />
        </div>
    </div>
</template>

<style scoped>
</style>