<script setup>
import { defineProps, computed } from 'vue';
import { fractionalExcretionParameters } from '@/data/parameters.js';

const props = defineProps({
    calculatedParameters: {
        type: Array,
        default: () => []
    },
})

const feParameters = computed(() => {
    return props.calculatedParameters.reduce((acc, param) => {
            const mapping = fractionalExcretionParameters.find(m => m.label === param.parameter_name);
            if (mapping) {
                acc.push({
                    parameterValue: param.parameter_value.toFixed(2),
                    displayName: mapping.displayName,
                    unit: mapping.unit
                });
            }
            return acc;
        }, []);
})

</script>

<template>
    <div class="border p-3 rounded">
        <h3>Fractional Excretion</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Parameter</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="parameter in feParameters" :key="parameter.label">
                    <td>{{ parameter.displayName }}</td>
                    <td>{{ parameter.parameterValue }} {{ parameter.unit }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped></style>