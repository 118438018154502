// src/data/parameters.js
export const plasmaValueParameters = [
    {
        label: "p_natrium",
        displayName: "Natrium",
        units: ["mmol/l"],
        selectedUnit: "mmol/l",
        previousUnit: "mmol/l",
        backendUnit: "mmol/l",
        unitConversion: [1],
        unitReferenceRanges: ["(135-145)"],
        selectedUnitReferenceRange: "(135-145)",
        value: null,
    },
    {
        label: "p_osmolality",
        displayName: "Osmolality",
        units: ["mOsm/l"],
        selectedUnit: "mOsm/l",
        previousUnit: "mOsm/l",
        backendUnit: "mOsm/l",
        unitConversion: [1],
        unitReferenceRanges: ["(280-296)"],
        selectedUnitReferenceRange: "(280-296)",
        value: null,
    },
    {
        label: "p_kalium",
        displayName: "Kalium",
        units: ["mmol/l"],
        selectedUnit: "mmol/l",
        previousUnit: "mmol/l",
        backendUnit: "mmol/l",
        unitConversion: [1],
        unitReferenceRanges: ["(3.5-5.0)"],
        selectedUnitReferenceRange: "(3.5-5.0)",
        value: null,
    },
    {
        label: "p_creatinin",
        displayName: "Creatinin",
        units: ["µmol/l", "mg/dL"],
        selectedUnit: "mg/dL",
        previousUnit: "mg/dL",
        backendUnit: "µmol/l",
        unitConversion: [88.4, 1],
        unitReferenceRanges: ["(50-120)", "(0.5-1.6)",],
        selectedUnitReferenceRange: "(0.5-1.6)",
        value: null,
    },
    {
        label: "p_bicarbonate",
        displayName: "Bicarbonate",
        units: ["mmol/l"],
        selectedUnit: "mmol/l",
        previousUnit: "mmol/l",
        backendUnit: "mmol/l",
        unitConversion: [1],
        unitReferenceRanges: ["(22-28)"],
        selectedUnitReferenceRange: "(22-28)",
        value: null,
    },
    {
        label: "p_glucose",
        displayName: "Glucose",
        units: ["mmol/l", "mg/dL"],
        selectedUnit: "mg/dL",
        previousUnit: "mg/dL",
        backendUnit: "mmol/l",
        unitConversion: [0.0555, 1],
        unitReferenceRanges: ["(5-15)", "(90-250)"],
        selectedUnitReferenceRange: "(90-250)",
        value: null,
    },
    {
        label: "p_uric_acid",
        displayName: "Uric acid",
        units: ["mg/dL"],
        selectedUnit: "mg/dL",
        previousUnit: "mg/dL",
        backendUnit: "mg/dL",
        unitConversion: [1],
        unitReferenceRanges: ["(2-7)"],
        selectedUnitReferenceRange: "(2-7)",
        value: null,
    },
    {
        label: "p_nt_prob_np",
        displayName: "NT-pro-BNP",
        units: ["pg/mL"],
        selectedUnit: "pg/mL",
        previousUnit: "pg/mL",
        backendUnit: "pg/mL",
        unitConversion: [1],
        unitReferenceRanges: ["(0-300)"],
        selectedUnitReferenceRange: "(0-300)",
        value: null,
    },
    {
        label: "p_albumin",
        displayName: "Albumin",
        units: ["g/L", "g/dL"],
        selectedUnit: "g/L",
        previousUnit: "g/L",
        backendUnit: "g/L",
        unitConversion: [1, 0.1],
        unitReferenceRanges: ["(35-50)", "(3.5-5.0)"],
        selectedUnitReferenceRange: "(35-50)",
        value: null,
    },
    {
        label: "p_urea",
        displayName: "",
        units: ["mg/dL", "mmol/L"],
        selectedUnit: "mg/dL",
        previousUnit: "mg/dL",
        backendUnit: "mg/dL",
        unitConversion: [2.8, 1],
        unitReferenceRanges: ["BUN (8-25)", "Urea (1.5-10)"],
        selectedUnitReferenceRange: "BUN (8-25)",
        value: null,
    },
]

export const plasmaBoolParameters = [
    {
        label: "p_ht",
        displayName: "Hematocrit rise",
        value: null
    },
]

export const urineValueParameters = [
    {
        label: "u_natrium",
        displayName: "Natrium",
        units: ["mmol/l"],
        selectedUnit: "mmol/l",
        previousUnit: "mmol/l",
        backendUnit: "mmol/l",
        unitConversion: [1],
        unitReferenceRanges: [null],
        selectedUnitReferenceRange: null,
        value: null,
    },
    {
        label: "u_osmolality",
        displayName: "",
        units: ["mOsm/l", "kg/l"],
        selectedUnit: "mOsm/l",
        previousUnit: "mOsm/l",
        backendUnit: "mOsm/l",
        customConversion: [
            function(value) {
                return value / (1000 * 35) + 1;
            },
            function(value) {
                return (value - 1) * 1000 * 35;
            },
        ],
        unitReferenceRanges: ["Osmolality", "Specific gravity"],
        selectedUnitReferenceRange: "Osmolality",
        value: null,
    },
    {
        label: "u_kalium",
        displayName: "Kalium",
        units: ["mmol/l"],
        selectedUnit: "mmol/l",
        previousUnit: "mmol/l",
        backendUnit: "mmol/l",
        unitConversion: [1],
        unitReferenceRanges: [null],
        selectedUnitReferenceRange: null,
        value: null,
    },
    {
        label: "u_creatinin",
        displayName: "Creatinin",
        units: ["µmol/l"],
        selectedUnit: "µmol/l",
        previousUnit: "µmol/l",
        backendUnit: "µmol/l",
        unitConversion: [1],
        unitReferenceRanges: [null],
        selectedUnitReferenceRange: null,
        value: null,
    },
    {
        label: "u_bicarbonate",
        displayName: "Bicarbonate",
        units: ["mmol/l"],
        selectedUnit: "mmol/l",
        previousUnit: "mmol/l",
        backendUnit: "mmol/l",
        unitConversion: [1],
        unitReferenceRanges: [null],
        selectedUnitReferenceRange: null,
        value: null,
    },
    {
        label: "u_uric_acid",
        displayName: "Uric acid",
        units: ["mg/dL"],
        selectedUnit: "mg/dL",
        previousUnit: "mg/dL",
        backendUnit: "mg/dL",
        unitConversion: [1],
        unitReferenceRanges: [null],
        selectedUnitReferenceRange: null,
        value: null,
    },
    {
        label: "u_ph",
        displayName: "pH",
        units: [""],
        selectedUnit: "",
        previousUnit: "",
        backendUnit: "",
        unitConversion: [1],
        unitReferenceRanges: [null],
        selectedUnitReferenceRange: null,
        value: null,
    },
];

export const urineBoolParameters = [
    
    {
        label: "u_protein",
        displayName: "Proteinuria",
        value: null
    },
    {
        label: "u_glucose",
        displayName: "Glycosuria",
        value: null
    },
]

export const booleanParameters = [
    {
        label: "hypovolemia",
        displayName: "Hypovolemia",
        value: null
    },
    {
        label: "edema",
        displayName: "Edema",
        value: null
    },
    {
        label: "low_bp",
        displayName: "Low blood pressure",
        value: null
    },
    {
        label: "hypo_na_48",
        displayName: "Acute hyponatremia",
        value: null
    },
    {
        label: "diuretics_use",
        displayName: "Diuretics",
        value: null
    },
    {
        label: "psychofarmaca",
        displayName: "Psychofarmaca",
        value: null
    },
    {
        label: "diarrhea",
        displayName: "Diarrhea",
        value: null
    },
    {
        label: "vomiting",
        displayName: "Vomiting",
        value: null
    },
];

export const netChangeParameters = [
    {
        label: "natrium_change",
        displayName: "Natrium",
        options: [
            { value: 'increasing', text: 'Increasing' },
            { value: 'decreasing', text: 'Decreasing' },
            { value: null, text: '-' }
        ],
        selectedOption: null,
    },
    {
        label: "urine_volume_change",
        displayName: "Urine Volume",
        options: [
            { value: 'increasing', text: 'Increasing' },
            { value: 'decreasing', text: 'Decreasing' },
            { value: null, text: '-' }
        ],
        selectedOption: null
    },
]

export const fractionalExcretionParameters = [
    {
        label: "fe_natrium",
        displayName: "Fractional Excretion Natrium",
        unit: "%"
    },
    {
        label: "fe_potassium",
        displayName: "Fractional Excretion Potassium",
        unit: "%"
    },
    {
        label: "fe_urea",
        displayName: "Fractional Excretion Urea",
        unit: "%"
    },
    {
        label: "fe_uricacid",
        displayName: "Fractional Excretion Uric Acid",
        unit: "%"
    },
]

export function convertValue(value, fromConversionFactor, toConversionFactor) {
    const valueBaseUnit = value * (1 / fromConversionFactor)
    const convertedValue = valueBaseUnit * toConversionFactor
    return Number(convertedValue.toFixed(2))
}