<script setup>
import { defineModel } from 'vue';

import OptionParameterInput from './OptionParameterInput.vue'
const parameters = defineModel()

</script>

<template>
    <div class="container">
        <div class="row mb-2">
            <h5>Response to saline infusion</h5>
        </div>
        <div class="row" v-for="(parameter, i) in parameters" :key="parameter.name">
            <OptionParameterInput v-model="parameters[i]" />
        </div>
    </div>
</template>

<style scoped></style>
