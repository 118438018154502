<script setup>
import { defineModel } from "vue";
import { convertValue } from '@/data/parameters.js'

const parameter = defineModel()

function handleUnitChange(parameter) {
    const selectedUnitIdx = parameter.units.indexOf(parameter.selectedUnit)
    if (parameter.value != null) {
        const previousUnitIdx = parameter.units.indexOf(parameter.previousUnit)
        
        if (parameter.customConversion) {
            const func = parameter.customConversion[previousUnitIdx]
            parameter.value = Number(func(parameter.value).toFixed(4))
        } else {
            const fromConversionFactor = parameter.unitConversion[previousUnitIdx]
            const toConversionFactor = parameter.unitConversion[selectedUnitIdx]
            parameter.value = convertValue(parameter.value, fromConversionFactor, toConversionFactor)
        }

        parameter.previousUnit = parameter.selectedUnit
        parameter.selectedUnitReferenceRange = parameter.unitReferenceRanges[selectedUnitIdx]
    }
    else {
        parameter.value = null
        parameter.previousUnit = parameter.selectedUnit
        parameter.selectedUnitReferenceRange = parameter.unitReferenceRanges[selectedUnitIdx]
    }

}

</script>


<template>
    <div class="row align-items-center">
        <div class="col-8">
            <div class="input-container">
                    <input
                        type="number"
                        class="form-control"
                        placeholder="" 
                        step="0.001"
                        data-toggle="tooltip"
                        v-model="parameter.value">
                    <label>{{ parameter.displayName }} {{ parameter.selectedUnitReferenceRange }}</label>
            </div>
        </div>

        <!-- Unit Selection -->
        <div class="col-4">
            <select class="form-control"
                :class="{ 'one-option': parameter.units.length === 1 }"
                :disabled="parameter.units.length === 1"
                v-model="parameter.selectedUnit"
                @change="handleUnitChange(parameter, selectedUnit)">
                <option v-for="unit in parameter.units" :key="unit" :value="unit">
                {{ unit }}
            </option>
        </select>
</div>
    </div>

</template>


<style scoped>
.input-container {
    position: relative;
    margin-bottom: 5px;
    margin-top: 5px;
    /* Adjust spacing as needed */
}

.input-container input {
    border: 1px solid #ccc;
    padding: 10px;
    padding-top: 20px;
    /* Make room for the label */
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-container label {
    position: absolute;
    left: 10px;
    top: 15px;
    white-space: nowrap;
    /* Adjust based on padding of input */
    background-color: #fff;
    transition: all .1s;
    pointer-events: none;
    /* Allows clicking through the label to focus the input */
    color: #999;
}

.input-container input:focus+label,
.input-container input:not(:placeholder-shown)+label {
    top: 3px;
    /* Move above the input */
    font-size: 12px;
    /* Make the label smaller */
    color: #050404;
    background-color: transparent;
}

/* Similar styles for select elements */
.form-control {
    border: 1px solid #ccc;
    padding: 15px 12px;
    /* Adjust padding to visually match the input fields */
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    /* Ensure consistency with input fields */
    appearance: none;
    /* Standard way to remove default styling */
    position: relative;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
}

.one-option {
    background-color: #e9ecef;
    /* Bootstrap's grey-200 for consistency */
    color: #6c757d;
    /* Bootstrap's grey-600 for readable contrast */
}

div[title]:hover::before {
  content: attr(title);
  background-color: #000000;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
}
</style>