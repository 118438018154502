<script setup>
import { ref } from 'vue';
import { fetchWrapper } from '@/helpers';
import { baseURL } from '@/data/config.js';
import DiagnosisInputContainer from "@/components/DiagnosisInputContainer"
import DiagnosisOutputContainer from "@/components/DiagnosisOutputContainer"

const diagnoses = ref([]);
const calculatedParameters = ref([]);

async function getDiagnosis(formData) {
    try {
        const response = await fetchWrapper.post(`${baseURL}/api/v1/diagnosis/diagnose/`, formData);
        diagnoses.value = response.diagnoses;
    } catch (error) {
        console.error("There was an error with the request:", error);
    }

}

async function getCalculatedParameters(formData) {
    try {
        const response = await fetchWrapper.post(`${baseURL}/api/v1/diagnosis/calculate-parameters/`, formData);
        calculatedParameters.value = response.calculated_parameters;
    } catch (error) {
        console.error("There was an error with the request:", error);
    }
}

async function handleFormSubmit(formData) {
    getDiagnosis(formData);
    getCalculatedParameters(formData);
}


</script>

<template>
    <div class="container">
        <div class="row g-3">
            <div class="col-8">
                <DiagnosisInputContainer @formSubmitted="handleFormSubmit" />
            </div>
            <div class="col-4">
                <DiagnosisOutputContainer :diagnoses="diagnoses" :calculatedParameters="calculatedParameters" />
            </div>
        </div>
    </div>
</template>