<script setup>
import { defineModel } from "vue";

const parameter = defineModel()


</script>

<template>
    <!-- Boolean Parameters Row -->
    <div class="row align-items-center mb-2">
        <div class="col-md-6 ">
            <label>{{ parameter.displayName }}</label>
        </div>

        <div class="col-md-3 d-flex justify-content-center align-items-center">
            <input
                type="checkbox"
                id="true"
                v-model="parameter.value"
                :true-value="true"
                :false-value="null" />
        </div>
        <div class="col-md-3 d-flex justify-content-center align-items-center">
            <input
                type="checkbox"
                id="false"
                v-model="parameter.value"
                :true-value="false"
                :false-value="null" />
        </div>
    </div>
</template>