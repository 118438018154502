<script setup>
import { defineModel } from "vue";

const parameter = defineModel()

</script>

<template>
    <div class="row mb-2">
        <div class="col-md-6 d-flex align-items-center">
            <label>{{ parameter.displayName }}</label>
        </div>
        <div class="col-md-6">
            <select :id="`dropdown-${parameter.label}`" class="form-control" v-model="parameter.selectedOption">
                <option v-for="option in parameter.options" :key="option.value" :value="option.value">
                    {{ option.text }}
                </option>
            </select>
        </div>
    </div>


</template>


<style scoped>
.input-container {
    position: relative;
    margin-bottom: 5px;
    margin-top: 5px;
    /* Adjust spacing as needed */
}

.input-container input {
    border: 1px solid #ccc;
    padding: 10px;
    padding-top: 20px;
    /* Make room for the label */
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-container label {
    position: absolute;
    left: 10px;
    top: 15px;
    /* Adjust based on padding of input */
    background-color: #fff;
    transition: all .1s;
    pointer-events: none;
    /* Allows clicking through the label to focus the input */
    color: #999;
}

.input-container input:focus+label,
.input-container input:not(:placeholder-shown)+label {
    top: 3px;
    /* Move above the input */
    font-size: 12px;
    /* Make the label smaller */
    color: #050404;
    background-color: transparent;
}

/* Similar styles for select elements */
.form-control {
    border: 1px solid #ccc;
    padding: 15px 12px;
    /* Adjust padding to visually match the input fields */
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    /* Ensure consistency with input fields */
    appearance: none;
    /* Standard way to remove default styling */
    position: relative;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
}

.one-option {
    background-color: #e9ecef;
    /* Bootstrap's grey-200 for consistency */
    color: #6c757d;
    /* Bootstrap's grey-600 for readable contrast */
}
</style>