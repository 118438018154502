<script setup>
import { ref, defineEmits } from 'vue';
import PlasmaParameterInput from '@/components/PlasmaParameterInput.vue';
import UrineParameterInput from '@/components/UrineParameterInput.vue';
import ClinicalParameterInput from '@/components/ClinicalParameterInput.vue';
import ResponseToSalineInput from '@/components/ResponseToSalineInput.vue'

import { plasmaValueParameters, plasmaBoolParameters, urineValueParameters, urineBoolParameters, booleanParameters, netChangeParameters, convertValue } from '@/data/parameters.js';

const plasmaPars = ref(plasmaValueParameters)
const plasmaBoolParams = ref(plasmaBoolParameters)
const urinePars = ref(urineValueParameters)
const urineBoolParams = ref(urineBoolParameters)
const boolPars = ref(booleanParameters)
const netChangeParams = ref(netChangeParameters)

const emit = defineEmits(["formSubmitted"])

function getFormData() {
    return {
        parameters: [
            ...plasmaPars.value
                .filter(param => param.value !== '')
                .map(param => ({
                    parameter_name: param.label,
                    parameter_value: getBackendUnitValue(param),
                })),
            ...urinePars.value
                .filter(param => param.value !== '')
                .map(param => ({
                    parameter_name: param.label,
                    parameter_value: getBackendUnitValue(param),
                })),
            ...boolPars.value
                .filter(param => param.value !== '')
                .map(param => ({
                    parameter_name: param.label,
                    parameter_value: param.value,
                })),
                ...urineBoolParams.value
                .filter(param => param.value !== '')
                .map(param => ({
                    parameter_name: param.label,
                    parameter_value: param.value,
                })),
                ...plasmaBoolParams.value
                .filter(param => param.value !== '')
                .map(param => ({
                    parameter_name: param.label,
                    parameter_value: param.value,
                })),
            ...netChangeParams.value
                .filter(param => param.value !== '')
                .map(param => ({
                    parameter_name: param.label,
                    parameter_value: param.selectedOption,
                }))
        ]
    }
}

function getBackendUnitValue(parameter) {
    if (parameter.value == null) {
        return null
    }

    const selectedUnitIdx = parameter.units.indexOf(parameter.selectedUnit)
    const backendUnitIdx = parameter.units.indexOf(parameter.backendUnit)

    if (parameter.customConversion) {
        if (selectedUnitIdx == backendUnitIdx) {
            return parameter.value
        } else {
            const func = parameter.customConversion[selectedUnitIdx]
            return Number(func(parameter.value).toFixed(4))
        }
    } else {
        const fromConversionFactor = parameter.unitConversion[selectedUnitIdx]
        const toConversionFactor = parameter.unitConversion[backendUnitIdx]
        const newValue = convertValue(parameter.value, fromConversionFactor, toConversionFactor)
        return newValue
    }
}

function onSubmit() {
    emit('formSubmitted', getFormData());
}

function setValueToNull(parametersRef) {

    const parameters = parametersRef.value;
    parameters.forEach(param => {
        param.value = null
    })

}

function setSelectedOptionToNull(parametersRef) {

const parameters = parametersRef.value;
parameters.forEach(param => {
    param.selectedOption = null
})

}

function clearAll() {
    setValueToNull(plasmaPars)
    setValueToNull(urinePars)
    setValueToNull(boolPars)
    setValueToNull(urineBoolParams)
    setValueToNull(plasmaBoolParams)
    setValueToNull(boolPars)
    setSelectedOptionToNull(netChangeParams)
}
</script>


<template>
    <div class="container">
        <div class="row g-2 mb-2">
            <div class="col-6">

                <div class="row g-2 mb-2">
                    <div class="col">
                        <div class="border p-3 rounded">
                            <PlasmaParameterInput v-model:parameters="plasmaPars" v-model:boolParameters="plasmaBoolParams" />
                        </div>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col">
                        <div class="border p-3 rounded">
                            <ResponseToSalineInput v-model="netChangeParams" />
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-6">
                <div class="row g-2 mb-2">
                    <div class="col">
                        <div class="border p-3 rounded">
                            <UrineParameterInput v-model:parameters="urinePars" v-model:boolParameters="urineBoolParams"/>
                        </div>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col">
                        <div class="border p-3 rounded">
                            <ClinicalParameterInput v-model="boolPars" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row g-2">
            <button @click="onSubmit" class="btn btn-primary">Submit</button>
            <button @click="clearAll" class="btn btn-outline-primary">Clear All</button>
        </div>
    </div>

</template>

<style scoped></style>