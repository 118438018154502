<script setup>
import { defineModel } from 'vue';

import BooleanParameterInput from './BooleanParameterInput.vue'
const parameters = defineModel()

</script>

<template>
    <div class="container">
        <div class="row mb-2">
            <div class="col-6">
                <h5>Clinical</h5>
            </div>
            <div class="col-3"><strong>Yes</strong></div>
            <div class="col-3"><strong>No</strong></div>
        </div>
        <div class="row" v-for="(parameter, i) in parameters" :key="parameter.label">
            <BooleanParameterInput v-model="parameters[i]" />
        </div>
    </div>
</template>

<style scoped></style>