import { createRouter, createWebHistory } from 'vue-router';
import accountRoutes from './account.routes';
import { useAuthStore } from '@/stores';
import { DiagnosisHome } from '@/views';


export const router = createRouter({
    history: createWebHistory("/"),
    linkActiveClass: 'active',
    routes: [
        { path: '/', component: DiagnosisHome, name: "Diagnosis"},
        { ...accountRoutes },
        // catch all redirect to home page
        { path: '/:pathMatch(.*)*', redirect: '/' }
    ]
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page 
    const publicPages = ['/account/login'];
    const authRequired = !publicPages.includes(to.path);
    const authStore = useAuthStore();

    if (authRequired && !authStore.user) {
        authStore.returnUrl = to.fullPath;
        return '/account/login';
    }
});

router.beforeEach((to, from, next) => {
    document.title = `${ process.env.VUE_APP_TITLE } - ${to.name}`
    next()
  })
