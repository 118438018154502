<script setup>
import { defineModel } from 'vue';
import ValueParameterInput from './ValueParameterInput.vue';
import BooleanParameterInput from './BooleanParameterInput.vue';

const parameters = defineModel("parameters")
const boolParameters = defineModel("boolParameters")

</script>

<template>
    <div class="container">
        <div class="row">
            <h5>Urine Measurements</h5>
        </div>
        <div class="row" v-for="(parameter, i) in parameters" :key="parameter.name">
            <ValueParameterInput v-model="parameters[i]">
            </ValueParameterInput>
        </div>
        <div class="row mb-2 mt-2">
            <div class="col-6">
                <h5></h5>
            </div>
            <div class="col-3"><strong>Yes</strong></div>
            <div class="col-3"><strong>No</strong></div>
        </div>
        <div class="row" v-for="(boolParameter, i) in boolParameters" :key="boolParameter.label">
            <BooleanParameterInput v-model="boolParameters[i]" />
        </div>
    </div>
</template>

<style scoped></style>