<script setup>
import { defineProps, computed } from 'vue';
const props = defineProps({
    diagnoses: {
        type: Array,
        validator(value) {
            return value.every(item =>
                typeof item.diagnosis_name === 'string' &&
                typeof item.diagnosis_score === 'number'
            );
        },
        default: () => []
    }
})


const topDiagnoses = computed(() => {
    const sortedDiagnoses = props.diagnoses.slice().sort((a, b) => b.diagnosis_score - a.diagnosis_score);
            return sortedDiagnoses.slice(0, 10);
})
</script>

<template>
    <div class="border p-3 rounded">
        <h3>Top Diagnosis</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Diagnosis Name</th>
                    <th>Score</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(diagnosis, index) in topDiagnoses" :key="diagnosis.diagnosis_name">
                    <td>{{ index + 1 }}</td>
                    <td>{{ diagnosis.diagnosis_name }}</td>
                    <td>{{ diagnosis.diagnosis_score }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
</style>